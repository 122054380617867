import React, { useEffect } from 'react';

import { BlocksModel } from '../../interfaces/blocks';

import useBlocks from '../../hooks/api/blocks';
import {
  IdColumn,
  createdAtColumn,
  descriptionAndIDColumn,
  modifiedAtColumn,
} from '../../components/base/v2/table/columns';
import GenericListPage from '../genericList';
import useDocumentTitle from '../../hooks/documentTitle';
import { cleanFilters } from '../../components/base/v2/table/stores';

const BlocksList: React.FC = () => {
  useDocumentTitle('Trex - Blocos');
  const { getBlocks } = useBlocks();
  const { resetsearchText, resetfilterBy, resetfilters } = cleanFilters();

  useEffect(() => {
    resetsearchText();
    resetfilterBy();
    resetfilters();
  }, []);

  return (
    <GenericListPage<BlocksModel>
      actionButton={{
        pushPath: '/blocks/create',
        text: ' Criar novo bloco',
      }}
      breadcrumb={{
        items: [{ label: 'Início', href: '/' }, { label: 'Blocos' }],
      }}
      title="Blocos"
      table={{
        fetch: {
          apiCall: getBlocks,
          title: 'blocos',
        },
        body: {
          columns: [
            descriptionAndIDColumn('/blocks/info'),
            IdColumn,
            createdAtColumn,
            modifiedAtColumn,
          ],
          hasQuery: true,
          searchLabel: 'Busque por nome ou ID de um bloco',
          accessURL: '/blocks/info',
        },
      }}
    />
  );
};

export default BlocksList;
