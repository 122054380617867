import React, { useState } from 'react';
import { useAtom } from 'jotai';
import { useHistory } from 'react-router-dom';
import { Icon, MenuItem, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { FaRegClone } from 'react-icons/fa';

import { initialDataBlocksAtom } from '../stores';
import useBlocks from '../../../hooks/api/blocks';
import { useToastFunctions } from '../../../utils/useToastFunction';
import DoubleCheckModal from '../../base/v2/DoubleCheckModal';
import InputText from '../../base/v2/inputs/text';
import InputSelect from '../../base/v2/inputs/select';

export default function CloneModal(): React.ReactElement {
  const { push } = useHistory();
  const { createBlock } = useBlocks();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { showToast } = useToastFunctions();
  const [initialData] = useAtom(initialDataBlocksAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [cloneData, setCloneData] = useState({
    description: '',
    allowReview: false,
  });

  const onClone = async (): Promise<void> => {
    setIsLoading(true);

    const sendData = {
      allow_review: cloneData.allowReview,
      description: cloneData.description,
      nodes: initialData.nodes,
    };

    try {
      const res = await createBlock({ data: sendData });

      if ([200, 201, 204].includes(res.request.status)) {
        showToast({
          title: 'Bloco clonado',
          status: 'success',
        });
        push(`/blocks/info?id=${res.data.id}`);
      } else {
        const { reason } = res.request.response;
        showToast({
          title: 'Erro ao clonar bloco',
          description: `Não foi possível clonar o bloco: ${reason}`,
          status: 'error',
        });
      }
    } catch (error) {
      showToast({
        title: 'Erro ao clonar bloco',
        description:
          'Não foi possível clonar o bloco. Tente novamente mais tarde.',
        status: 'error',
      });
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  const handleDescription = (e: React.ChangeEvent<{ value: string }>) => {
    setCloneData({ ...cloneData, description: e.target.value });
  };

  const handleAllowReview = (e: React.ChangeEvent<{ value: string }>) => {
    setCloneData({
      ...cloneData,
      allowReview: e.target.value === 'true',
    });
  };

  return (
    <>
      <MenuItem icon={<Icon as={FaRegClone} />} onClick={onOpen}>
        Clonar bloco
      </MenuItem>

      <DoubleCheckModal
        title="Clonar bloco existente"
        description={`Ao clonar o bloco ${initialData.description}, é importante lembrar que quaisquer alterações não salvas não serão incluídas na cópia.`}
        isOpen={isOpen}
        onClose={onClose}
        modal={{ size: 'xl' }}
        primaryButton={{
          colorScheme: 'green',
          text: 'Clonar bloco',
          action: onClone,
        }}
        isLoading={isLoading}
      >
        <Stack
          spacing="medium"
          background="white"
          p="medium"
          borderRadius="extra-large"
        >
          <Text fontWeight="bold">Informações do bloco</Text>
          <Stack direction="row" spacing="medium">
            <InputText
              label="Bloco"
              value={cloneData.description}
              onChange={handleDescription}
              formcontrol={{}}
            />

            <InputSelect
              label="Permitir revisão"
              options={[
                { option: 'Sim', value: 'true' },
                { option: 'Não', value: 'false' },
              ]}
              value={cloneData.allowReview.toString()}
              onChange={handleAllowReview}
              formcontrol={{}}
            />
          </Stack>
        </Stack>
      </DoubleCheckModal>
    </>
  );
}
