/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import MaterialTable, {
  Query,
  QueryResult,
  Components,
} from '@material-table/core';
import { useAtom } from 'jotai';

import * as S from './styles';
import * as I from './interfaces';

import { searchTextAtom } from './stores';
import {
  CustomIcon,
  CustomLoadingComponent,
  EnhancedToolbar,
} from './components';

export default function DefaultTable({
  actions: propsActions,
  components: propsComponents = {},
  localization: propsLocalization,
  options: propsOptions,
  searchLabel = ' ',
  accessURL,
  isSearch = true,
  hasSearch = true,
  filters,
  searchMinWidth,
  hasQuery,
  ...rest
}: Readonly<I.DefaultTableProps>): React.ReactElement {
  const { push } = useHistory();
  const [, setSearchText] = useAtom(searchTextAtom);
  const navigate = useCallback(
    (rowData) => {
      if (isSearch) {
        return push({ pathname: accessURL, search: `id=${rowData.id}` });
      }
      return push({ pathname: `${accessURL}/${rowData.id}` });
    },
    [push, accessURL]
  );

  const components: Components = {
    OverlayLoading: CustomLoadingComponent,
    Toolbar: hasSearch
      ? EnhancedToolbar({
          propsComponents,
          label: searchLabel,
          filters,
          searchMinWidth,
          hasQuery,
        })
      : () => <></>,
    ...propsComponents,
  };

  const actions = [
    {
      icon: CustomIcon,
      tooltip: 'Visualizar',
      onClick: (_: unknown, rowData: { id: string }) => navigate(rowData),
    },
    ...(propsActions ?? []),
  ];

  const options = {
    addRowPosition: 'first' as const,
    emptyRowsWhenPaging: false,
    search: hasSearch,
    sorting: true,
    paginationType: 'normal' as const,
    exportButton: false,
    selection: false,
    pageSizeOptions: [5, 10, 25, 50],
    pageSize: 10,
    debounceInterval: 500,
    actionsColumnIndex: -1,
    ...propsOptions,
  };

  const localization = {
    grouping: {
      placeholder: 'Arraste os cabeçalhos aqui para organizar por grupos',
      groupedBy: 'Agrupado por:',
    },
    pagination: {
      labelDisplayedRows: `{from} - {to} de {count}`,
      labelRowsPerPage: 'Linhas por página ',
      labelRowsSelect: '',
      firstAriaLabel: 'Primeira página',
      firstTooltip: 'Primeira página',
      nextAriaLabel: 'Próxima página',
      nextTooltip: 'Próxima página',
      previousAriaLabel: 'Página anterior',
      previousTooltip: 'Página anterior',
      lastAriaLabel: 'Ultima página',
      lastTooltip: 'Ultima página',
    },
    toolbar: {
      nRowsSelected: '{0} linhas selecionadas',
      exportName: 'Exportar para CSV',
      exportTitle: 'Exportar',
      searchPlaceholder: 'Digite aqui',
      addRemoveColumns: 'Adicionar ou Remover colunas',
      showColumnsTitle: 'Configurar colunas',
      showColumnsAriaLabel: 'Configurar colunas',
    },
    header: {
      actions: ' ',
    },
    body: {
      emptyDataSourceMessage: 'Nenhum registro encontrado',
      filterRow: {
        filterTooltip: 'Filtro',
      },
      editRow: {
        deleteText: 'Tem certeza disso?',
        saveTooltip: 'Sim',
        cancelTooltip: 'Cancelar',
      },
      editTooltip: 'Editar',
      deleteTooltip: 'Excluir',
      addTooltip: 'Adicionar',
    },
    ...propsLocalization,
  };

  useEffect(() => {
    return () => {
      setSearchText('');
    };
  }, []);

  return (
    <S.Container>
      <MaterialTable
        actions={accessURL ? actions : propsActions}
        components={components}
        options={options}
        localization={localization}
        {...rest}
      />
    </S.Container>
  );
}

export const fetchDataTable = async <Model extends object>(
  query: Query<Model>,
  fetchData: (queryProps: Query<Model>) => Promise<I.FetchDataResult<Model>>,
  onTotalCalculated?: (total: number) => void,
  showErrorToast?: () => void,
  filters?: any // TODO: Tipar corretamente essa prop que antes era Filter<Model>[]
): Promise<QueryResult<Model>> => {
  if (filters) {
    query.filters = filters;
  }

  try {
    const result = await fetchData(query);
    const total = Math.min(result.data.total_of_items, 10000);
    if (onTotalCalculated) onTotalCalculated(total);

    if (result.data.items) {
      return {
        data: result.data.items,
        page: result.data.page - 1,
        totalCount: total,
      };
    }

    return {
      data: [],
      page: result.data.page - 1,
      totalCount: result.data.total_of_items,
    };
  } catch (error) {
    if (showErrorToast) {
      showErrorToast();
    }

    return {
      data: [],
      page: 0,
      totalCount: 0,
    };
  }
};
