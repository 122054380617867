import React, { useEffect } from 'react';
import { ClientModel } from '../../interfaces/client';
import {
  IdColumn,
  ExternalIdColumn,
  nameAndIDColumn,
  isRunnableColumn,
} from '../../components/base/v2/table/columns';

import useClients from '../../hooks/api/clients';
import GenericListPage from '../genericList';
import useDocumentTitle from '../../hooks/documentTitle';
import { cleanFilters } from '../../components/base/v2/table/stores';

const ClientList: React.FC = () => {
  useDocumentTitle('Trex - Clientes');
  const { getClients } = useClients();
  const { resetsearchText, resetfilterBy, resetfilters } = cleanFilters();

  useEffect(() => {
    resetsearchText();
    resetfilterBy();
    resetfilters();
  }, []);

  return (
    <GenericListPage<ClientModel>
      actionButton={{
        pushPath: '/client/create',
        text: ' Criar novo cliente',
      }}
      breadcrumb={{
        items: [{ label: 'Início', href: '/' }, { label: 'Cliente' }],
      }}
      title="Cliente"
      table={{
        fetch: {
          apiCall: getClients,
          title: 'cliente',
        },
        body: {
          columns: [
            nameAndIDColumn('/client/info'),
            IdColumn,
            ExternalIdColumn,
            isRunnableColumn,
          ],
          hasQuery: true,
          searchLabel: 'Busque por nome ou ID de um cliente',
          accessURL: '/client/info',
        },
      }}
    />
  );
};

export default ClientList;
